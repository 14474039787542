import { blogHolidays2023 } from "@/app/blog/(posts)/holidays_2023/blogHolidays2023";
import { blogVision2023 } from "@/app/blog/(posts)/vision_2023/blogVision2023";
import { ArticleMetadata } from "@/app_components/articles/ArticleMetadata";
import { blogAccounts2024 } from "@/app/blog/(posts)/accounts_2024/blogAccounts2024";
import { blogPanda2024 } from "@/app/blog/(posts)/panda_2024/blogPanda2024";
import { blogTiming2024 } from "@/app/blog/(posts)/timing_2024/blogTiming2024";
import { blogMasters2024 } from "@/app/blog/(posts)/masters_2024/blogMasters2024";
import { blogReview2024 } from "@/app/blog/(posts)/review_2024/blogReview2024";
import { blogHalloween2024 } from "@/app/blog/(posts)/halloween_2024/blogHalloween2024";


export const blogPosts = [
    blogHalloween2024,
    blogReview2024,
    blogMasters2024,
    blogTiming2024,
    blogPanda2024,
    blogAccounts2024,
    blogHolidays2023,
    blogVision2023,
];

export const latestBlogPost: ArticleMetadata = blogPosts[0];
